import * as emoji from "node-emoji";
import { useEffect, useRef, useState } from "react";
import "./App.css";
import Stopwatch from "./Stopwatch";
import CountUp from "react-countup";
import classNames from "classnames";

const chatId =
  document.location.pathname.split("/").reverse()[0] ||
  new URL(window.location.href).searchParams.get("q") ||
  "013f27781044988331802c952de1d4cf";
const hostname = "https://api.svt.se/emoji-rain/";

const fetchData = async () => {
  const res = await fetch(`${hostname}${chatId}/rain`);
  const data = await res.json();
  return Object.keys(data).reduce((memo, key) => {
    memo.push({
      name: key,
      score: data[key],
    });
    return memo;
  }, []);
};

const formatData = (data, prevData) => {
  return data.map((item) => {
    const previous = prevData.find((n) => n.name === item.name);
    return {
      ...item,
      score: item.score - previous.score,
    };
  });
};

function App() {
  const [counter, setCounter] = useState(0);
  const [data, setData] = useState([]);
  const snapshot = useRef([]);
  const intervalId = useRef();

  const onStart = () => {
    (async () => {
      try {
        const snapshotData = await fetchData();
        snapshot.current = snapshotData;
        setData(formatData(snapshotData, snapshotData));
      } catch (e) {
        // console.log('Fail')
      }
    })();

    if (intervalId.current) {
      clearInterval(intervalId.current);
    }

    intervalId.current = setInterval(() => {
      setCounter((count) => count + 1);
    }, 1000);
  };

  useEffect(() => {
    if (!snapshot.current?.length) return;

    (async () => {
      try {
        const freshData = await fetchData();
        setData(formatData(freshData, snapshot.current));
      } catch (e) {}
    })();
  }, [counter]);

  const onStop = () => {
    clearInterval(intervalId.current);
    intervalId.current = null;
    setCounter(0);
  };

  // Onetime fetch on pageload so to display the available set of emojis
  useEffect(() => {
    (async () => {
      try {
        const data = await fetchData();
        setData(data);
      } catch (e) {}
    })();
  }, []);

  const appClassNames = classNames({
    App: true,
    skuggslembg: [
      "f884c38a12b0cf61a23c84ce61072796",
      "ff370f80880628ca81f628e8e6b0c26e",
    ].some((item) => {
      return document.location.pathname.includes(item);
    }),
  });

  return (
    <div className={appClassNames}>
      <header className="App-header">
        <h1 className="heading">Emojiomröstning</h1>
      </header>
      <ol className="list">
        {data &&
          data.map((item, idx) => {
            return (
              <li key={`${idx}`} className="listitem">
                <span className="name" title={item.name}>
                  {emoji.has(item.name) && emoji.get(item.name)}
                </span>
                {emoji.has(item.name) && (
                  <span className="score">
                    <CountUp
                      preserveValue
                      end={item.score}
                      duration={item.score === 0 ? 0 : 3}
                      separator=""
                    />
                  </span>
                )}
              </li>
            );
          })}
      </ol>
      <Stopwatch onStart={onStart} onStop={onStop} />
    </div>
  );
}

export default App;
